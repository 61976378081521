<template>
  <Container :customClass="'overflow-auto'">
    <Header
      :back-action="() => $emit('onBack')"
      @goHome="() => $emit('goHome')"
    />
    <div class="mt-10 sm:mt-20">
      <div class="w-full block relative">
        <div
          class="
            max-w-lg
            mx-auto
            bg-white
            shadow-lg
            rounded
            px-10
            py-10
            w-full
            relative
          "
        >
          <div class="z-20 flex flex-col items-start justify-start">
            <Text
              size="xl"
              weight="semibold"
              color="gray-700"
              content="We just sent you an email"
              class="text-center"
            />
            <span class="text-sm text-gray-400 mb-3 mt-4"
              >We just sent you an email. All you have to do is click the link
              in the email.</span
            >
            <span class="text-xs text-gray-400 text-center mb-3 mt-4">
              Haven't gotten it yet?</span
            >
			<div class="flex items-center space-x-3">
				<a class="text-primary text-xs underline font-semibold" @click="$emit('onSubmit')">Resend the email</a>
				<a class="text-primary text-xs underline font-semibold" @click="$emit('onLogin')">Login Now</a>
			</div>
			
          </div>
        </div>
      </div>
    </div>
  </Container>
</template>

<script>
import Container from "../../atoms/Containers/FullWidthBannerContainer/FullWidthBannerContainer.vue";
import Header from "../../organisms/Headers/Simple/Simple.vue";
import LoginForm from "../../organisms/FormLayouts/LoginForm/LoginForm.vue";
import Text from "../../atoms/Text/Text.vue";

/**
 - Use it to show a Login template
 **/
export default {
  components: {
    Container,
    Header,
    LoginForm,
    Text,
  },
  props: {
    form: {
      type: Object,
      default: () => {},
    },
    texts: {
      type: Array,
      default: () => [],
    },
    isLoading: {
      type: Boolean,
      default: false,
    },
  },
};
</script>
