<template>
  <Template
    v-bind="{
      ...params,
      isLoading,
    }"
    @onSubmit="() => onResend()"
    @onLogin="() => $router.push('/login')"
    @goTo="(path) => $router.push(path)"
    @onBack="() => $router.go(-1)"
    @goHome="() => $router.push('/')"
  />
</template>
<script>
import Template from "../components/templates/ResetPasswordSuccess/ResetPasswordSuccess";
import { c } from "@/components/constants.js";
import { Settings } from "../../settings";

export default {
  components: {
    Template,
  },
  methods: {
    async onResend() {
      if (this.isLoading) return false;
      try {
        this.isLoading = true;
        const { triggerPasswordReset } =
          await this.actions.user.triggerPasswordReset({
            email: this.$route.query.email?.toLowerCase(),
            domain: Settings.mainDomain,
          });
        console.log(triggerPasswordReset);
        if (triggerPasswordReset) {
          this.actions.alert.showSuccess({
            message: "Email resent successfully!",
          });
        } else {
          this.actions.alert.showError({ message: "The email doesn't exist." });
        }
      } catch (e) {
        console.log(e);
      } finally {
        this.isLoading = false;
      }
    },
  },
  data() {
    return {
      params: c.resetPasswordSuccess,
      isLoading: false,
    };
  },
  mounted() {
    console.log(this.$route.query?.email);
    if (!this.$route.query?.email) {
      this.actions.alert.showError({
        message: "Invalid email address. Please try again later",
      });
      this.$router.go(-1);
    }
  },
};
</script>
